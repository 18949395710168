import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

// support tickets requests

export function getSupportTickets (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/support-tickets${queries}`,
    method: 'get'
  })
}

export function getSupportTicket (id) {

  return axios({
    url: `v1/admin/support-tickets/${id}`,
    method: 'get'
  })
}

export function getAttachment (url) {

  return axios({
    url: `v1${url}`,
    method: 'get'
  })
}

export function insertSupportTicket (payload) {

  return axios({
    url: 'v1/admin/support-tickets',
    data: payload,
    method: 'post'
  })
}

export function sendMessageSupportTicket (id, payload) {

  return axios({
    url: `v1/admin/support-tickets/${id}/messages`,
    data: payload,
    method: 'post'
  })
}

export function sendSupportTicketToService (id) {

  return axios({
    url: `v1/admin/support-tickets/${id}/send`,
    method: 'post'
  })
}

// organization tickets requests

export function getOrganizationTickets (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/organizational-tickets${queries}`,
    method: 'get'
  })
}

export function getOrganizationTicket (id) {

  return axios({
    url: `v1/admin/organizational-tickets/${id}`,
    method: 'get'
  })
}

export function insertOrganizationTicket (payload) {

  return axios({
    url: 'v1/admin/organizational-tickets',
    data: payload,
    method: 'post'
  })
}

export function sendMessageOrganizationTicket (id, payload) {

  return axios({
    url: `v1/admin/organizational-tickets/${id}/messages`,
    data: payload,
    method: 'post'
  })
}

export function sendOrganizationTicketToService (id) {

  return axios({
    url: `v1/admin/organizational-tickets/${id}/send`,
    method: 'post'
  })
}
